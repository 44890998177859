<template>
  <div>
    <a-form
      class="iotplt-nested-search-form"
      :form="form"
      @submit="handleSearch"
    >
      <a-row :gutter="24">
        <a-col :span="6" class="item-display">
          <a-form-item label="账单批次">
            <a-input
              v-decorator="['no', {
                normalize: this.$lodash.trim
              }]"
              placeholder="请输入账单批次"
              allow-clear
            />
          </a-form-item>
        </a-col>

        <a-col :span="6" class="item-display">
          <a-form-item label="账单时间">
            <a-range-picker
              style="width: 100%;"
              v-decorator="['created_at']"
            />
          </a-form-item>
        </a-col>

        <a-col :span="6">
          <a-form-item>
            <a-space>
              <a-button type="primary" html-type="submit">
                搜索
              </a-button>
              <a-button @click="handleReset">
                刷新
              </a-button>
            </a-space>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>
<script>
import { formatRangeBeginDate, formatRangeEndDate } from '@/utils/time'

export default {
  name: 'SearchAgentSubscribeBill',
  data() {
    return {
      form: this.$form.createForm(this, { name: 'agent_subscribe_bill_search' })
    }
  },
  computed: {
    agentId() {
      return parseInt(this.$route.params.id)
    }
  },
  methods: {
    handleSearch(e) {
      e.preventDefault()
      const fieldsValue = this.form.getFieldsValue()
      const values = {
        ...fieldsValue,
        created_at_begin: formatRangeBeginDate(fieldsValue.created_at),
        created_at_end: formatRangeEndDate(fieldsValue.created_at)
      }
      this.$emit('submit', values)
    },

    handleReset() {
      this.form.resetFields()
      this.$emit('submit', {})
    }
  }
}
</script>

<style lang="less" scoped>
.item-display{
  width: 350px;
}
</style>
